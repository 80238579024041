// app/javascript/controllers/cropper_controller.js

import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs"

export default class extends Controller {
  static targets = ["fileInput", "previewImage", "cropperImage", "cropperModal"]

  connect() {
    this.cropper = null;
    console.log("Cropper controller connected");
  }

  chooseFile() {
    const file = this.fileInputTarget.files[0];

    if (file) {
      console.log('File selected:', file);

      const reader = new FileReader();

      reader.onload = (event) => {
        console.log('FileReader loaded image:', event.target.result);

        // Set the image source in the modal
        this.cropperImageTarget.src = event.target.result;

        // Display the modal
        this.cropperModalTarget.classList.remove('hidden');

        // Initialize the Cropper.js instance
        if (this.cropper) {
          console.log('Destroying existing cropper instance');
          this.cropper.destroy();
        }

        console.log('Initializing Cropper');
        this.initCropper();
      };

      reader.readAsDataURL(file); // Load the image as a data URL
    } else {
      console.log('No file selected');
    }
  }

  initCropper() {
    this.cropper = new Cropper(this.cropperImageTarget, {
      aspectRatio: 1, // Maintain a 1:1 aspect ratio for circular crop
      viewMode: 2,    // Allows some flexibility for cropping outside boundaries
      dragMode: 'crop', // Ensures that the crop box is draggable
      autoCropArea: 1, // Ensures the crop area is fully visible initially
      autoCrop: true,  // Automatically displays the crop box
      cropBoxMovable: true, // Allows moving of the crop box
      cropBoxResizable: true, // Allows resizing of the crop box
      guides: false,   // Hides the grid overlay (optional)
      center: true,    // Centers the crop box in the image
      highlight: false,
      background: false,
      ready: () => {
        console.log('Cropper is ready');
        this.applyCircularCrop(); // Apply circular crop
      }
    });
  }

  applyCircularCrop() {
    const cropBox = this.element.querySelector('.cropper-view-box');
    const face = this.element.querySelector('.cropper-face');
    if (cropBox && face) {
      cropBox.style.borderRadius = '50%'; // Make the crop box circular
      face.style.borderRadius = '50%';
    }
  }

  closeModal() {
    this.cropperModalTarget.classList.add('hidden');
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = null;
    }
  }

  cropImage() {
    if (!this.cropper) return;

    const canvas = this.cropper.getCroppedCanvas({
      width: 300,
      height: 300
    });

    canvas.toBlob((blob) => {
      this.previewImageTarget.src = canvas.toDataURL();

      const file = new File([blob], 'cropped_image.png', { type: 'image/png' });
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      this.fileInputTarget.files = dataTransfer.files;

      this.closeModal();
    }, 'image/png');
  }
}
