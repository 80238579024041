// app/javascript/controllers/search_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "input", "results"];

  connect() {
    this.timeout = null;
    this.minLength = 3;
    console.log("Search controller connected");
    this.inputTarget.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  debouncedSubmit() {
    console.log("Debounced submit called");
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const inputLength = this.inputTarget.value.length;
      console.log("Timeout fired, input length:", inputLength);
      if (inputLength >= this.minLength) {
        this.submit();
      } else if (inputLength === 0) {
        this.clearResults();
      }
    }, 300);
  }

  handleKeydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default form submission behavior
      console.log("Enter key pressed");
      //this.submit(); // Trigger the form submission method
    }
  }

  submit() {
    console.log("Submitting search");
    const url = this.formTarget.action;
    const params = new URLSearchParams(new FormData(this.formTarget));

    fetch(`${url}?${params}`, {
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Received response:", data);
        this.updateBusinessCards(data);
      })
      .catch((error) => console.error("Error:", error));
  }

  clearResults() {
    console.log("Clearing results");
    this.resultsTarget.innerHTML = "";
  }

  updateBusinessCards(businesses) {
    if (businesses.length === 0) {
      this.resultsTarget.innerHTML = this.noResultsHTML();
    } else {
      this.resultsTarget.innerHTML = businesses
        .map(this.renderBusinessCard)
        .join("");
    }
  }

  renderBusinessCard(business) {
    return `
      <div class="bg-white p-6 rounded-lg shadow-lg transition hover:shadow-xl">
        <a href="/businesses/${business.id}/public_view" class="block text-center">
          <!-- Business Logo -->
          <img src="${business.logo || 'https://placehold.co/100x100/CFCAE2/FFF?text=Tipz&font=montserrat/CFCAE2/FFF?text=Tipz&font=montserrat/CFCAE2/FFF?text=Tipz&font=montserrat'}" alt="Business Logo" class="w-24 h-24 mx-auto rounded-full object-cover mb-4">

          <!-- Business Information -->
          <h3 class="text-2xl font-semibold text-gray-800 mb-2">${business.name}</h3>

          <p class="text-gray-500 mb-2">${business.address}</p>
          
          ${business.website ? `
            <p class="text-blue-500 mb-2 flex items-center justify-center">
              <span class="material-icons text-blue-500 mr-1">language</span>
              <span class="hover:underline">${business.website}</span>
            </p>` : ""}
          
          ${business.phone_number ? `
            <p class="text-gray-500 flex items-center justify-center">
              <span class="material-icons text-gray-500 mr-1">phone</span>
              ${business.phone_number}
            </p>` : ""}
        </a>
      </div>
    `;
  }

  submitEmployee() {
    console.log("Submitting search");
    const url = this.formTarget.action;
    const params = new URLSearchParams(new FormData(this.formTarget));

    fetch(`${url}?${params}`, {
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Received response:", data);
        this.updateEmployeeCards(data);
      })
      .catch((error) => console.error("Error:", error));
  }

  updateEmployeeCards(employees) {
    if (employees.length === 0) {
      this.resultsTarget.innerHTML = this.noResultsHTML();
    } else {
      this.resultsTarget.innerHTML = employees
        .map(this.renderEmployeesCard)
        .join("");
    }
  }

  renderEmployeesCard(employee) {
    console.log('--------------renderEmployeesCard')
    console.log(employee)
    let div_element = '';
    if (employee.tippable) {
      div_element = `
        <a class="flex items-center p-5 bg-white rounded-lg shadow-md employee-card mb-4 hover:shadow-lg transition-shadow duration-300 ease-in-out" href="/tips/new?employee_id=${employee.id}">
          <img class="w-24 h-24 mr-4 rounded-full" src="${employee.avatar}">
          <div class="flex-1 employee-info">
            <h3 class="text-xl font-bold">${employee.name}</h3>
            <p class="text-gray-600">Employee at ${employee.business}</p>
            <div class="mt-2">
              <span class="edit-button px-4 py-2 rounded-full text-white bg-purple-600 flex items-center justify-center">
                Tip ${employee.name}
              </span>
            </div>
          </div>
        </a>`;
    } else {
      div_element = `
        <div class="flex items-center p-5 bg-white rounded-lg shadow-md employee-card mb-4">
          <img class="w-24 h-24 mr-4 rounded-full" src="${employee.avatar}">
          <div class="flex-1 employee-info">
            <h3 class="text-xl font-bold">${employee.name}</h3>
            <p class="text-gray-600">Employee at ${employee.business}</p>
          </div>
        </div>
      `;
    }

    return div_element;
  }

  submitPublicEmployee() {
    console.log("Submitting search");
    const url = this.formTarget.action;
    const params = new URLSearchParams(new FormData(this.formTarget));

    fetch(`${url}?${params}`, {
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Received response:", data);
        this.updatePublicEmployeeCards(data);
      })
      .catch((error) => console.error("Error:", error));
  }

  updatePublicEmployeeCards(employees) {
    if (employees.length === 0) {
      this.resultsTarget.innerHTML = this.noResultsHTML();
    } else {
      this.resultsTarget.innerHTML = employees
        .map(this.renderPublicEmployeesCard)
        .join("");
    }
  }

  renderPublicEmployeesCard(employee) {
    return `
      <div class="flex items-center p-5 bg-white rounded-lg shadow-md employee-card">
        <div class="flex-shrink-0">
            <img class="w-[90px] h-[90px] rounded-full object-cover" src="${employee.avatar}">
        </div>
        <div class="flex flex-col items-end flex-grow pl-4 text-right">
          <h3 class="mb-2 text-xl font-bold">${employee.name}</h3>
            <a class="tip-button px-4 sm:px-6 py-2 rounded-full text-white bg-purple-600 text-sm md:text-md whitespace-nowrap" href="/tips/new?employee_id=${employee.id}">Tip ${employee.name}</a></div> </div>
    `;
  }

  noResultsHTML() {
    return `
      <p class="text-center text-gray-500 py-4">No businesses found matching your search.</p>
    `;
  }
}
