import { Controller } from "@hotwired/stimulus";
import { loadStripe } from "@stripe/stripe-js";

export default class extends Controller {
  static targets = [
    "amount",
    "message",
    "name",
    "email",
    "stripeToken",
    "messages",
    "nextButton",
    "paymentOptionsSection",
    "paymentRequestButton",
    "overlay",
    "paymentElement",
  ];

  async connect() {
    try {
      // Fetch Stripe public key from meta tag and initialize Stripe
      const stripePublicKeyElement = document.querySelector(
        'meta[name="stripe-public-key"]'
      );
      if (!stripePublicKeyElement) {
        throw new Error("Stripe public key element not found in the DOM.");
      }

      const stripePublicKey = stripePublicKeyElement.getAttribute("content");
      if (!stripePublicKey) {
        throw new Error("Stripe public key is not available.");
      }

      // Load Stripe with the public key
      this.stripe = await loadStripe(stripePublicKey);
      console.log("Stripe initialized successfully.");

      // Setup input elements
      this.setupAmountInput();
    } catch (error) {
      console.error("Error during Stripe initialization:", error);
    }
  }

  async submit(event) {
    event.preventDefault();

    // Validate form before proceeding
    if (!this.validateForm()) {
      this.displayMessage("Please fill in all required fields.", "error");
      return;
    }

    // Proceed directly to payment after clicking "Next"
    await this.proceedToPayment();
  }

  async proceedToPayment(event) {
    if (event) {
      event.preventDefault();
    }

    try {
      // Fetch the client secret for the payment intent from the server
      this.clientSecret = await this.fetchPaymentIntentClientSecret();

      // Initialize Stripe Elements using the client secret
      this.elements = this.stripe.elements({ clientSecret: this.clientSecret });

      // Display available payment options using the Payment Element
      this.displayPaymentOptions();

      // Hide the "Next" button after showing payment options
      this.hideNextButton();
    } catch (error) {
      console.error("Error fetching payment intent client secret:", error);
      this.displayMessage("An error occurred. Please try again.", "error");
    }
  }

  async fetchPaymentIntentClientSecret() {
    const amount = this.getAmountValue();
    const businessId = document.querySelector('[name="business_id"]')?.value;
    const employeeId = document.querySelector('[name="employee_id"]')?.value;

    // Get form details for tipper information
    const tipperName = this.nameTarget.value.trim();
    const tipperEmail = this.emailTarget.value.trim();
    const message = this.messageTarget.value.trim();

    // Build the payload with all necessary details
    const payload = {
      amount,
      tipper_name: tipperName,
      tipper_email: tipperEmail,
      message: message,
    };

    if (businessId) {
      payload.business_id = businessId;
    } else if (employeeId) {
      payload.employee_id = employeeId;
    }

    const response = await fetch("/tips/payment_intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (data.error) {
      throw new Error(data.error);
    }

    return data.client_secret;
  }

  displayPaymentOptions() {
    if (!this.hasPaymentOptionsSectionTarget || !this.hasPaymentElementTarget) {
      throw new Error(
        "Payment options section or payment element target not found."
      );
    }

    // Show the payment options section
    this.paymentOptionsSectionTarget.classList.remove("hidden");

    // Initialize the Stripe Payment Element
    const options = {
      clientSecret: this.clientSecret,
    };
    this.paymentElement = this.elements.create("payment", options);
    this.paymentElement.mount(this.paymentElementTarget);
    console.log("Payment Element mounted successfully.");
  }

  async confirmPayment(event) {
    event.preventDefault();

    // Hide the "Next" button to avoid showing it during payment processing
    this.hideNextButton();

    try {
      // Confirm the payment using the Payment Element
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${window.location.origin}/tips/success`, // Full URL to redirect on successful payment
          payment_method_data: {
            billing_details: {
              name: this.nameTarget.value,
              email: this.emailTarget.value,
            },
          },
        },
      });

      if (error) {
        console.error("Payment failed:", error);
        this.displayMessage(error.message, "error");
      } else {
        console.log("Payment successful! Redirecting to success page...");
      }
    } catch (e) {
      console.error("Error confirming payment:", e);
      this.displayMessage(
        "An error occurred during payment. Please try again.",
        "error"
      );

      // For unexpected or severe errors (e.g., network failure), show the "Next" button to restart the flow
      this.showNextButton();
    }
  }

  hideNextButton() {
    if (this.hasNextButtonTarget) {
      this.nextButtonTarget.classList.add("hidden");
    }
  }

  showNextButton() {
    if (this.hasNextButtonTarget) {
      this.nextButtonTarget.classList.remove("hidden");
    }
  }

  getAmountValue() {
    let amountValue = this.amountTarget.value.trim();
    amountValue = amountValue.replace(/[$,]/g, "");
    return parseFloat(amountValue) * 100; // Convert to cents
  }

  validateForm() {
    let amountValue = this.amountTarget.value.trim();
    amountValue = amountValue.replace(/[$,]/g, "");
    const nameValue = this.nameTarget.value.trim();
    const emailValue = this.emailTarget.value.trim();

    if (isNaN(amountValue) || parseFloat(amountValue) <= 0) {
      console.error("Invalid tip amount entered:", amountValue);
      return false;
    }

    if (nameValue === "" || emailValue === "" || amountValue === "") {
      console.error("Form validation failed - required fields missing.");
      return false;
    }

    return true;
  }

  setupAmountInput() {
    const amountInput = this.amountTarget;

    amountInput.addEventListener("input", (e) => {
      let value = e.target.value;
      value = value.replace(/[^\d]/g, "");
      if (value) {
        value = (parseInt(value) / 100).toFixed(2);
      }

      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        e.target.value =
          "$" +
          numericValue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
      } else {
        e.target.value = value;
      }
    });

    amountInput.addEventListener("focusout", (e) => {
      let numericValue = e.target.value.replace(/[$,]/g, "");
      e.target.dataset.rawValue = numericValue;

      if (numericValue) {
        numericValue = parseFloat(numericValue).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        e.target.value = "$" + numericValue;
      }
    });

    amountInput.addEventListener("focus", (e) => {
      if (e.target.dataset.rawValue) {
        e.target.value = e.target.dataset.rawValue;
      }
    });
  }

  displayMessage(message, type) {
    if (!this.hasMessagesTarget) {
      console.error("Messages target not found.");
      return;
    }

    this.messagesTarget.textContent = message;
    this.messagesTarget.className =
      type === "error"
        ? "w-full p-4 mb-4 text-white bg-red-500 rounded-md"
        : "w-full p-4 mb-4 text-white bg-green-500 rounded-md";
    this.messagesTarget.classList.remove("hidden");
  }
}
