import { Controller } from "@hotwired/stimulus";
import QRCodeStyling from "qr-code-styling";

export default class extends Controller {
  static targets = ["qrCodeContainer"];

  connect() {
    const data = this.element.getAttribute('data-qr-code-data');
    if (data) {
      this.generateQRCode(data);
    } else {
      console.error('QR code data not found');
    }
  }

  generateQRCode(data) {
    const qrCode = new QRCodeStyling({
      type: "canvas",
      shape: "square",
      width: 300,
      height: 300,
      data: data,
      margin: 10,
      qrOptions: {
        typeNumber: "0",
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
      imageOptions: {
        saveAsBlob: true,
        hideBackgroundDots: true,
        imageSize: 0.5,
        margin: 4,
      },
      dotsOptions: {
        type: "dots",
        color: "#000000",
        roundSize: true,
      },
      backgroundOptions: {
        round: 0,
        color: "#ffffff",
      },
      image: "/Tipz.png",
      dotsOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#6a1a4c",
          color2: "#6a1a4c",
          rotation: "0",
        },
      },
      cornersSquareOptions: {
        type: "dot",
        color: "#000000",
      },
      cornersSquareOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      cornersDotOptions: {
        type: "",
        color: "#000000",
      },
      cornersDotOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      backgroundOptionsHelper: {
        colorType: {
          single: true,
          gradient: false,
        },
        gradient: {
          linear: true,
          radial: false,
          color1: "#ffffff",
          color2: "#ffffff",
          rotation: "0",
        },
      },
    });

    qrCode.append(this.qrCodeContainerTarget);

    // Add click event listener for download
    this.qrCodeContainerTarget.addEventListener('click', () => this.downloadQRCode(qrCode, data));
  }

  downloadQRCode(qrCode, data) {
    const fileName = this.generateFileName(data);
    qrCode.download({ name: fileName, extension: "png" });
  }

  generateFileName(data) {
    const url = new URL(data);
    const businessId = url.searchParams.get('business_id');
    const employeeId = url.searchParams.get('employee_id');

    if (businessId) {
      return `tipz-b-${businessId}`;
    } else if (employeeId) {
      return `tipz-e-${employeeId}`;
    } else {
      return 'tipz-qrcode';
    }
  }
}
