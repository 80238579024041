import { Controller } from "@hotwired/stimulus";
import Cropper from "cropperjs";

export default class extends Controller {
  static targets = ["avatar", "input", "preview", "fileInput"];

  connect() {
    this.initializeCropper();
    this.fileInputTarget.addEventListener("change", this.handleFileSelect.bind(this));
  }

  initializeCropper() {
    this.cropper = new Cropper(this.avatarTarget, {
      aspectRatio: 1,
      viewMode: 1,
      preview: this.previewTarget,
      crop: (event) => {
        const canvas = this.cropper.getCroppedCanvas();
        this.inputTarget.value = canvas.toDataURL("image/png");
        this.updateAvatarPreview(canvas.toDataURL("image/png"));
      },
    });
  }

  handleFileSelect(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.avatarTarget.src = e.target.result;
        this.cropper.replace(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  crop() {
    const canvas = this.cropper.getCroppedCanvas();
    this.inputTarget.value = canvas.toDataURL("image/png");
    this.updateAvatarPreview(canvas.toDataURL("image/png"));
  }

  updateAvatarPreview(dataUrl) {
    this.avatarTarget.src = dataUrl;
  }

  disconnect() {
    if (this.cropper) {
      this.cropper.destroy();
    }
    this.fileInputTarget.removeEventListener("change", this.handleFileSelect.bind(this));
  }
}
